<template>

  <div :class="'similar ' + props.type" v-if="list && list.length">

    <h2 class="divider-sec">
      {{ title }}       
    </h2>


    <HorizontalScroll
      :arrows="true"
      :arrows-show-hover="true"
      v-if="list"
      >

      <div class="list">
        <template v-for="pro in list">
          <BrandProductItem
            :nm="pro.name"
            :nmalt="pro.name"
            :avatar="pro.cover_s"
            :section="pro.link"
            :rate="pro.item_stat.avg_rate"
            :cnt_harvests="pro.item_stat.cnt_harvests"
            :cnt_diaries="pro.item_stat.cnt_reports"
            />
        </template>
      </div>
    </HorizontalScroll>


  </div>

</template>

<script setup>
  

const props = defineProps({
  title: {
    type: String,
    default: 'Similar'
  },
  type: {
    type: String,
    default: 'brand'
  },
  list: {
    type: Array,
    default: []
  }
});
 
</script>

<style scoped>



 

 
.similar {
  
}

.similar .list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
 
.similar .list :deep(.seed){
  flex-shrink: 0;
}


@container pb (max-width: 550px) {
  .similar .list :deep(.seed){
    width: calc(50% - 40px);
  }
}

</style>
